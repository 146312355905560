/**
 * Dot Notation Manipulation
 *
 * {a:{b=c}} <==> {a.b=c}
 */

import { isPlainObject, set } from "lodash";

/**
 * To Dot Notation
 *
 * - convert a deep object to a dot notation object
 */

const toDotNotationSub = (result, source, prefix = "") => {
  for (let key in source) {
    if (isPlainObject(source[key])) {
      toDotNotationSub(result, source[key], prefix + key + ".");
    } else {
      result[prefix + key] = source[key];
    }
  }
};

export const toDotNotation = (source) => {
  const result = {};
  toDotNotationSub(result, source);
  return result;
};

/**
 * From Dot Notation
 *
 * - convert a dot notation object to a deep object
 */

export const fromDotNotation = (source) => {
  const result = {};
  for (let key in source) {
    set(result, key, source[key]);
  }
  return result;
};
