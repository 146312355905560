import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  QuerySnapshot,
  setDoc,
} from "firebase/firestore";
import { toDotNotation } from "src/lib/DotNotationLib";
import { getId } from "src/modules/base/lib";
import { IDFIELD } from "src/modules/base/schema";

import { firestoreCollectionToDataArray, firestoreDocToData } from "./lib";

let firebaseClient;

try {
  console.log("firebase::starting::client");
  const firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG);
  firebaseClient = initializeApp(firebaseConfig);
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error", err.stack);
  }
}

export default firebaseClient;

export const auth = getAuth(firebaseClient);

export const firestoreClient = getFirestore(firebaseClient);

export const firestoreClientQuery$ = async (
  collectionName: string,
  filters: any[] = [],
  processor$: any = firestoreCollectionToDataArray,
) => {
  let c = collection(firestoreClient, collectionName);
  const q = query(c, ...filters);
  const snapshot = await getDocs(q);
  const result = await processor$(snapshot);
  return result;
};

export const firestoreClientGet$ = async (collectionName: string, id: string): Promise<object | null> => {
  console.log("firestoreGet$", collectionName, id);
  try {
    const docRef = doc(firestoreClient, collectionName, id);
    const docSnap = await getDoc(docRef);
    return firestoreDocToData(docSnap);
  } catch (err) {
    console.error("firestoreGet$::error", err);
    throw err;
  }
};

export const firestoreClientSet$ = async (collectionName: string, values: any): Promise<string> => {
  try {
    // console.log("firestoreSet$", { collectionName, values });
    const id = values[IDFIELD];
    delete values[IDFIELD];
    if (id) {
      const docRef = doc(firestoreClient, collectionName, id);
      await setDoc(docRef, values);
      return id;
    } else {
      let c = collection(firestoreClient, collectionName);
      const docRef = await addDoc(c, values);
      return docRef.id;
    }
  } catch (err) {
    console.error("firestoreSet$::error", err);
    throw err;
  }
};

export const firestoreClientUpdate$ = async (collectionName: string, changes: any) => {
  try {
    if (!changes.id) throw new Error("No ID provided to update data");
    const id = changes[IDFIELD];
    delete changes[IDFIELD];
    console.log("firestoreUpdate$", collectionName, id, changes);
    changes = toDotNotation(changes);
    const docRef = doc(firestoreClient, collectionName, id);
    await setDoc(docRef, changes, { merge: true });
    return id;
  } catch (err) {
    console.error("firestoreUpdate$::error", err);
    throw err;
  }
};

export const firestoreClientDelete$ = async (collectionName: string, id: string | any) => {
  try {
    console.log("firestoreDelete$", collectionName, id);
    id = getId(id);
    if (!id) throw new Error("No ID provided to delete data");
    const docRef = doc(firestoreClient, collectionName, id);
    await deleteDoc(docRef);
    return id;
  } catch (err) {
    console.error("firestoreDelete$::error", err);
    throw err;
  }
};

export const firestoreClientDeleteWhere$ = async (collectionName: string, filters: any[]) => {
  const deleteProcessor$ = async (snapshot: QuerySnapshot) => {
    const all = [];
    snapshot.forEach((doc) => all.push(firestoreClientDelete$(collectionName, doc.id)));
    return Promise.all(all);
  };
  const result = await firestoreClientQuery$(collectionName, filters, deleteProcessor$);
  return result;
};
