import { InferType } from "yup";

import yup, { editedField, emailField, idField, notesField } from "../../lib/YupLib";
import { IDFIELD } from "../base/schema";
import { AddressSchema } from "../general/address-schema";

export const USER_TABLE_NAME = "user";

export const USER_STATUS_ACTIVE = "active";

export const UserProfileSchema = yup
  .object({
    [IDFIELD]: idField,

    // details
    name: yup.string().nullable().default(null).trim().required(),
    email: emailField.required(),
  })
  .defined();

export const UserAddressSchema = yup
  .object({
    [IDFIELD]: idField,

    // address
    address: AddressSchema.defined(),
  })
  .defined();

export const UserSchema = yup
  .object({
    [IDFIELD]: idField,

    // // details
    // name: yup.string().nullable().default(null).trim().required(),
    // email: emailField.required(),

    // access
    active: yup.boolean().required().default(true), // false, can't log in
    admin: yup.boolean().required().default(false),

    // // address
    address: AddressSchema.defined(),

    // phone
    phone: yup
      .string()
      .ensure()
      .required("Phone is a required field")
      .min(12, "Phone must be a valid Australian phone number")
      .max(12, "Phone must be a valid Australian phone number"),

    // Credit Card
    cardExpiry: yup.string().nullable().default(null),
    cardLast4: yup.string().nullable().default(null),
    cardFingerprint: yup.string().nullable().default(null),

    // Customer
    customerId: yup.string().nullable().default(null),

    // notes
    notes: notesField,

    // audit
    edited: editedField,
  })
  .concat(UserProfileSchema)
  .concat(UserAddressSchema)
  .defined();

export type UserData = InferType<typeof UserSchema>;
