import { createContext, useContext } from "react";

import { initializeStore, Store } from "./store";

export const StoreContext = createContext<Store>(null);

export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error("useStore must be used within StoreProvider");
  }
  return context;
};

const StoreProvider = ({ children, initialState: initialData }) => {
  const store = initializeStore(initialData);
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export default StoreProvider;
