import yup from "../../lib/YupLib";

export type VariantType = "Single" | "Couple" | "Family" | "Each";

export type VariantMealSize = "Small" | "Medium" | "Large";

export const VARIANT_SINGLE: VariantType = "Single";
export const VARIANT_COUPLE: VariantType = "Couple";
export const VARIANT_FAMILY: VariantType = "Family";
export const VARIANT_EACH: VariantType = "Each";

export const VARIANT_MEAL_SIZE_SMALL: VariantMealSize = "Small";
export const VARIANT_MEAL_SIZE_MEDIUM: VariantMealSize = "Medium";
export const VARIANT_MEAL_SIZE_LARGE: VariantMealSize = "Large";

export const VARIANT_NUM_OF_PEOPLE = {
  [VARIANT_SINGLE]: 1,
  [VARIANT_COUPLE]: 2,
  [VARIANT_FAMILY]: 4,
};

export const VARIANT_PRICE_SINGLE = {
  [VARIANT_SINGLE]: 13.99,
  [VARIANT_COUPLE]: 25.99,
  [VARIANT_FAMILY]: 49.99,
};

export const VARIANT_PRICE_PLAN = {
  [VARIANT_SINGLE]: 12.59,
  [VARIANT_COUPLE]: 23.39,
  [VARIANT_FAMILY]: 44.99,
};

export const VARIANT_PLAN_MINIMUM = {
  [VARIANT_SINGLE]: 1,
  [VARIANT_COUPLE]: 1,
  [VARIANT_FAMILY]: 1,
};

export const VARIANT_PLAN_QUANTITIES = {
  [VARIANT_SINGLE]: {
    [VARIANT_MEAL_SIZE_SMALL]: 5,
    [VARIANT_MEAL_SIZE_MEDIUM]: 7,
    [VARIANT_MEAL_SIZE_LARGE]: 10,
  },
  [VARIANT_COUPLE]: {
    [VARIANT_MEAL_SIZE_SMALL]: 3,
    [VARIANT_MEAL_SIZE_MEDIUM]: 4,
    [VARIANT_MEAL_SIZE_LARGE]: 5,
  },
  [VARIANT_FAMILY]: {
    [VARIANT_MEAL_SIZE_SMALL]: 2,
    [VARIANT_MEAL_SIZE_MEDIUM]: 3,
    [VARIANT_MEAL_SIZE_LARGE]: 4,
  },
};

export type VariantID = string;

export const variantGetNumOfPeople = (variant: VariantType) => VARIANT_NUM_OF_PEOPLE[variant] || 0;

export const variantGetMinimumOrder = (variant: VariantType) => VARIANT_PLAN_MINIMUM[variant] || 0;

export const variantGetPlanQuantities = (variant: VariantType) =>
  VARIANT_PLAN_QUANTITIES[variant] || {
    [VARIANT_MEAL_SIZE_SMALL]: 5,
    [VARIANT_MEAL_SIZE_MEDIUM]: 7,
    [VARIANT_MEAL_SIZE_LARGE]: 10,
  };

export const VariantSchema = yup.string().oneOf([VARIANT_SINGLE, VARIANT_COUPLE, VARIANT_FAMILY, VARIANT_EACH]);
