import { apiGet$, apiPost$ } from "../../lib/APILib";
import { store } from "../../services/store/store";

/**
 * Returns true or false if the suburb and postcode is serviceable
 * @param suburb
 * @param postcode
 */
export const deliveryCheckServiceableCheck$ = async (suburb: string, postcode: string) => {
  return await apiPost$(["delivery", "service", "check"], {
    suburb,
    postcode,
  });
};

/**
 * Returns true or false if the suburb and postcode is serviceable
 * @param suburb
 * @param postcode
 */
export const deliveryCheckServiceable$ = async (suburb: string, postcode: string) => {
  return await apiPost$(["delivery", "service", "serviceable"], {
    suburb,
    postcode,
  });
};

/**
 * Get the service dates from the delivery company
 * @param suburb
 * @param postcode
 */
export const deliveryServiceDays$ = async (suburb: string, postcode: string) => {
  return await apiPost$(["delivery", "service", "days"], {
    suburb,
    postcode,
  });
};

/**
 * Use a postcode to find suburbs
 * @param postcode
 */
export const deliverySuburbsFromPostcode$ = async (postcode: string) => {
  return await apiPost$(["delivery", "service", "suburbs"], {
    postcode,
  });
};

export const deliveryShippingZonesClientLoadStore$ = async () => {
  store.shippingZones = await apiGet$(["cache", "shipping-zones"]);
};
