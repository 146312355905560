import clsx from "clsx";
import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";

import LottieProgressSpinner from "./LottieProgressSpinner";

const MotionProgress: FC = () => {
  const router = useRouter();
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    const routeChangeStart = () => setTransitioning(true);
    const routeChangeCompleted = () => setTransitioning(false);
    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeCompleted);
    router.events.on("routeChangeError", routeChangeCompleted);

    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
      router.events.off("routeChangeComplete", routeChangeCompleted);
      router.events.off("routeChangeError", routeChangeCompleted);
    };
  }, [router]);

  if (!transitioning) return null;

  return (
    <div
      className={clsx(
        "fixed",
        "z-50",
        "h-screen w-screen",
        "top-0 left-0",
        "flex items-center justify-center",
        "bg-charcoal bg-opacity-50",
      )}
    >
      <LottieProgressSpinner />
    </div>
  );
};

export default MotionProgress;
