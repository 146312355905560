import { AnySchema } from "yup/lib/schema";

import { IDFIELD } from "../../modules/base/schema";

export const firestoreDocToData = (docSnap, schema: AnySchema | null = null): any | null => {
  if (!docSnap.exists) return null;
  let data = docSnap.data();
  if (schema) data = schema.cast(data, { stripUnknown: true });
  return { [IDFIELD]: docSnap.id, ...docSnap.data() };
};

export const firestoreCollectionToDataArray = (snapshot: any): any[] => {
  const result: any[] = [];
  snapshot.forEach((doc) => result.push(firestoreDocToData(doc)));
  return result;
};

export const firestoreCollectionToDataObject = (snapshot: any): any[] => {
  const result: any = {};
  snapshot.forEach((doc) => (result[doc.id] = firestoreDocToData(doc)));
  return result;
};

export type firestoreFilterFunction = (query: any) => any;
