import axios from "axios";
import fileDownload from "js-file-download";
import { keys, set } from "lodash";
import { currentTime } from "src/lib/TimeLib";
import { authGetIdToken$ } from "src/modules/auth/client";
import { getId } from "src/modules/base/lib";

const apiGetOptions$ = async (config = {}) => {
  const idToken = await authGetIdToken$();
  set(config, ["headers", "Authorization"], `Bearer ${idToken}`);
  return config;

  // console.log("apiGetOptions", { idtoken });
  // return {
  //   headers: {
  //     // accept: "application/json",
  //     Authorization: `Bearer ${idtoken}`,
  //   },
  // };
};

export type APIParts = any[];

export type APIQuery = null | any;

export const apiMakeURL = (params: APIParts, query: APIQuery = null) => {
  let url = params.map((part) => getId(part)).join("/");
  if (query) {
    const queryParts = keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join("&");
    if (queryParts) url += "?" + queryParts;
  }
  // console.log("api:url", url);
  return url;
};

export const apiGet$ = async (params: APIParts, query: APIQuery = null) => {
  // console.log("api:get", { params, query });
  const url = apiMakeURL(["/api", ...params], query);
  const options = await apiGetOptions$();
  const res = await axios.get(url, options);
  // console.log("api:get", { res });
  const data = res.data;
  if (!data.status) throw new Error(data.error || "API error");
  return data.data;
};

export const apiPost$ = async (params: APIParts, body?: any, query: APIQuery = null) => {
  // console.log("api:post", { params, query, body });
  const url = apiMakeURL(["/api", ...params], query);
  const options = await apiGetOptions$();
  const res = await axios.post(url, body, options);
  // console.log("api:post", { res });
  const data = res.data;
  if (!data.status) throw new Error(data.error || "API error");
  return data.data;
};

export const apiOpen$ = async (params: APIParts, query: APIQuery = null) => {
  const url = apiMakeURL(["/api", ...params], query);
  const win = window.open(url, "_blank");
  if (win) win.focus();
};

export const apiErrorResponse$ = (res, error) => {
  console.error("error", error);
  if (error.message === "access_denied") {
    res.status(401).json({ "@": currentTime(), status: false, error: "Access Denied" });
    return;
  }
  res.status(500).json({ "@": currentTime(), status: false, error: error.message });
};

export const apiPaymentErrorResponse$ = (res, error) => {
  console.error("error", error);
  if (error.message === "access_denied") {
    res.status(401).json({ "@": currentTime(), status: false, error: "Access Denied" });
    return;
  }
  res.status(200).json({ "@": currentTime(), status: false, error: error.message });
};

export const apiDownloadFile$ = async (params: APIParts, query: APIQuery = null) => {
  const url = apiMakeURL(["/api", ...params], query);
  const options = await apiGetOptions$({
    responseType: "blob",
  });
  const res = await axios.get(url, options);
  console.log("res", { res });
  const fileName = apiGetFileName(res.headers["content-disposition"]);
  fileDownload(res.data, fileName);
};

export const apiGetFileName = (contentDisposition: string) => {
  const fileName = contentDisposition.split("filename=")[1].split(";")[0].replace(/[^A-Za-z0-9-_\. \(\)]/g, "");
  return fileName;
};
