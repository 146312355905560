import React, { FC } from "react";

const CrossIcon: FC = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.82843" y="4.24268" width="2" height="10" transform="rotate(-45 2.82843 4.24268)" fill="currentColor" />
      <rect x="9.89948" y="2.82837" width="2" height="10" transform="rotate(45 9.89948 2.82837)" fill="currentColor" />
    </svg>
  );
};

export default CrossIcon;
