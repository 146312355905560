import { isObject, isUndefined } from "lodash";

import { BaseData, IDFIELD } from "./schema";

/**
 * Get ID
 *
 * - given an id or an entity containing an id, return the id
 */

export const getId = (id: string | BaseData | undefined) => (isUndefined(id) ? null : isObject(id) ? id[IDFIELD] : id);
