import { FirebaseError } from "firebase/app";
import {
  getIdToken,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { apiPost$ } from "src/lib/APILib";
import { toastSuccess } from "src/lib/ToastLib";
import { auth } from "src/services/firebase/client";

export const authGetIdToken$ = async () => {
  if (!auth.currentUser) return null;
  const result = await getIdToken(auth.currentUser);
  return result || null;
};

export const loginWithPopup$ = async () => {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
  toastSuccess("Login successful");
};

export const loginWithEmail$ = async (email, password) => {
  await signInWithEmailAndPassword(auth, email, password);
  toastSuccess("Login successful");
};

export const logout$ = async () => {
  await auth.signOut();
  toastSuccess("Logout successful");
};

// using client = email and password only
// export const loginCreateWithEmail$ = async (email, password) => {
//   const data = await auth.createUserWithEmailAndPassword(email, password);
//   console.log("data", data);
// };

export const loginCreateWithEmail$ = async (email, password, name, phone) => {
  const data = await apiPost$(["user", "create"], {
    name,
    email,
    password,
    phone,
  });
  console.log("data", data);
  await loginWithEmail$(email, password);
  toastSuccess("Login created successfully");
};

export const loginResetPassword$ = async (email) => {
  await sendPasswordResetEmail(auth, email);
  toastSuccess("Reset password email sent");
};

export const authFormatFirebaseLoginError = (err: FirebaseError) => {
  switch (err.code) {
    case "auth/user-not-found":
      return "Sorry, we couldn’t find an account with that username.";
    case "auth/wrong-password":
      return "Sorry, that password isn’t right.";
    default:
      return err.message;
  }
};
