import React, { Component } from "react";

class ErrorBoundary extends Component {
  state = { hasError: false, error: null, info: null };

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    if (this.state.hasError) {
      console.error("ERROR", this.state.error, this.state.info);
      return (
        <>
          <div>Something went wrong</div>
          <div>{this.state.error}</div>
          <div>{this.state.info}</div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
