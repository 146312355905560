import * as yup from "yup";

export default yup;

export const idField = yup.string().optional(); // can be null for new

export const nameField = yup.string().required();

export const optionalTextField = yup.string().defined().nullable().default(null);

export const requiredTextField = yup.string().required();

export const emailField = yup.string().email();

export const nutritionField = yup.string().nullable();

export const booleanField = yup.boolean().default(false);

export const moneyField = yup.number().nullable();

export const passwordField = yup.string().ensure();

export const notesField = yup.string().defined().ensure();

export const htmlField = yup.string().defined().ensure();

export const dateRegEx = /^\d{4}[./-]\d{2}[./-]\d{2}$/; // yyyy-mm-dd

export const dateStrField = yup.string().matches(dateRegEx, {
  message: "Incorrect date format",
  excludeEmptyString: true,
});

export const editedField = yup.number().optional();

export function cast<T extends yup.AnySchema>(data: any, schema: T) {
  return schema.cast(data, { stripUnknown: true });
}
