export type ProductType = "Main" | "Dessert" | "Fruit" | "Groceries" | "Herbs" | "Vegetable" | "Soup" | "Breakfast";

export const PRODUCT_TYPE_MAIN: ProductType = "Main";
export const PRODUCT_TYPE_DESSERT: ProductType = "Dessert";
export const PRODUCT_TYPE_FRUIT: ProductType = "Fruit";
export const PRODUCT_TYPE_GROCERIES: ProductType = "Groceries";
export const PRODUCT_TYPE_HERBS: ProductType = "Herbs";
export const PRODUCT_TYPE_VEGETABLE: ProductType = "Vegetable";
export const PRODUCT_TYPE_SOUP: ProductType = "Soup";
export const PRODUCT_TYPE_BREAKFAST: ProductType = "Breakfast";

export const PRODUCT_TYPES: ProductType[] = [
  PRODUCT_TYPE_MAIN,
  PRODUCT_TYPE_DESSERT,
  PRODUCT_TYPE_BREAKFAST,
  PRODUCT_TYPE_SOUP,
  PRODUCT_TYPE_FRUIT,
  PRODUCT_TYPE_GROCERIES,
  PRODUCT_TYPE_HERBS,
  PRODUCT_TYPE_VEGETABLE,
];
