// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const dsn =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://902381d033714583adcf7c377595ffec@o4504229950193664.ingest.sentry.io/4504229979029504";

// Config is set at build time
// process.env.NEXT_PUBLIC_VERCEL_ENV is replaced with the variable at build time
// https://nextjs.org/docs/basic-features/environment-variables#exposing-environment-variables-to-the-browser
const vercelEnvironment = process?.env?.NEXT_PUBLIC_VERCEL_ENV;

Sentry.init({
  dsn,
  environment: vercelEnvironment,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
