import { PRODUCT_TYPE_MAIN } from "./ProductTypeLib";
import { ProductCacheData, ProductData, ProductListData } from "./schema";
import { VARIANT_PRICE_PLAN, VARIANT_PRICE_SINGLE, VariantType } from "./VariantLib";

export const productGetURI = (product: ProductData | ProductListData | ProductCacheData) => `/eat/${product.handle}`;

export const productIsMain = (product: ProductData | ProductListData | ProductCacheData) =>
  product.type === PRODUCT_TYPE_MAIN;

export const productHasVariants = (product: ProductData | ProductListData | ProductCacheData) =>
  product.type === PRODUCT_TYPE_MAIN;

export const productGetPrice = (
  product: ProductData | ProductListData | ProductCacheData,
  variant: VariantType | null,
  inPlan: boolean = false,
): number => {
  if (!product) return 0;
  if (product.type !== PRODUCT_TYPE_MAIN) return product.price || 0;
  if (inPlan) return VARIANT_PRICE_PLAN[variant] || 0;
  return VARIANT_PRICE_SINGLE[variant] || 0;
};

export const productHasImage = (product: ProductData | ProductListData | ProductCacheData) => !!product?.image;

export const productGetImageSrc = (product: ProductData | ProductListData | ProductCacheData) => product?.image || ""; // "/noimage.svg";

export const productGetBackgroundSrc = (product: ProductData | ProductListData | ProductCacheData) =>
  `url(${productGetImageSrc(product)})`;

export const productSortWeight = (product: ProductListData) => {
  switch (true) {
    case product?.isNew:
      return 4;
    case product?.isBestSeller:
      return 3;
    case product?.isActive:
      return 2;
    default:
      return 1;
  }
};

export const productSort = (products: ProductListData[]) => {
  // Products on list pages are displayed in order of new, best sellers, everything else, out of stock
  // and then by title
  return products.sort((a, b) => {
    const diff = productSortWeight(b) - productSortWeight(a);
    if (diff) return diff;
    return a.title.localeCompare(b.title);
  });
};
