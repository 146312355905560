import { DateTime } from "luxon";

import { TIMEZONE } from "../config/appConfig";

export const currentDateTime = () => DateTime.now().setZone(TIMEZONE);

export const currentTime = () => DateTime.now().setZone(TIMEZONE).toMillis();

export const currentDateISO = () => DateTime.now().setZone(TIMEZONE).toISODate();

export const formatDate = (date) => {
  return DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("dd/MM/yyyy");
};

export const formatDeliveryDates = (dateArray) => {
  return dateArray
    .sort((a, b) => new Date(a).valueOf() - new Date(b).valueOf())
    .map((date) => formatDate(date))
    .join(", ");
};
