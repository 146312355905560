// TIMEZONE - What timezone should all orders be calculated in.
export const TIMEZONE = "Australia/Sydney";

// DELIVERYCUTOFFDAYS - How many days before the delivery date t is the cutoff
// Saturday orders are cut off on wednesday before (wed -> sat = 3)
// export const DELIVERYCUTOFFDAYS = 3;

// PLANFUTUREORDERSORDERSINTHELASTXDAYS - How many days before today should future orders be retrieved
export const PLANFUTUREORDERSORDERSINTHELASTXDAYS = 3;

// ORDERS_CHARGE_DAYS_IN_ADVANCE - How many advance should we be charging orders, src/modules/order/client.ts@orderServerProcessAll$
export const ORDERS_CHARGE_DAYS_IN_ADVANCE = 3;

// REGIONS - These are the delivery regions and their associated delivery days (e.g. 6 === Sat)
export const REGIONS = [
  {
    region: "Canberra",
    postcodes: [
      2600, 2601, 2602, 2603, 2604, 2605, 2606, 2607, 2609, 2611, 2612, 2614, 2615, 2617, 2900, 2902, 2903, 2904, 2905,
      2906, 2911, 2912, 2913, 2914,
      //   2618 (Hall)
    ],
    fringe: false,
    delivery: [6, 13, 20],
  },
  {
    region: "Queanbeyan",
    postcodes: [2619, 2620],
    fringe: false,
    delivery: [6, 13, 20],
  },
  {
    region: "New South Wales",
    postcodes: [],
    fringe: false,
    delivery: [5, 12, 19],
  },
  {
    region: "NSW Coast",
    postcodes: [2579, 2578, 2577, 2576, 2575, 2574, 2573, 2572, 2571, 2569, 2568, 2560, 2541, 2540, 2539, 2538, 2535, 2534,2533,2530,2529,2528,2527,2526,2525,2522,2520,2519,2518,2517,2516,2515,2508,2506,2505,2502,2500,2233],
    fringe: false,
    delivery: [5, 12, 19],
  },
  {
    region: "Australian Capital Territory",
    postcodes: [
      2600, 2601, 2602, 2603, 2604, 2605, 2606, 2607, 2609, 2611, 2612, 2614, 2615, 2617, 2900, 2902, 2903, 2904, 2905,
      2906, 2911, 2912, 2913, 2914,
      //   2618 (Hall)
    ],
    fringe: false,
    delivery: [6, 13, 20],
  },
  {
    region: "Victoria",
    postcodes: [],
    fringe: false,
    delivery: [6, 13, 20],
  },
];

export const PLAN_DELIVERY_DAYS = [6, 13, 20];

// PLAN_FUTURE_WEEKS - How many weeks ahead should orders be planned for
export const PLAN_FUTURE_WEEKS = 4;

export const STATES = [
  {
    name: "Select State",
    abbr: "",
    disabled: true,
  },
  {
    name: "New South Wales",
    abbr: "NSW",
  },
  {
    name: "Australian Capital Territory",
    abbr: "ACT",
  },
  {
    name: "Victoria",
    abbr: "VIC",
  },
  {
    name: "South Australia",
    abbr: "SA",
  },
  {
    name: "Western Australia",
    abbr: "WA",
  },
  {
    name: "Queensland",
    abbr: "QLD",
  },
  {
    name: "Northern Territory",
    abbr: "NT",
  },
  {
    name: "Tasmania",
    abbr: "TAS",
  },
];
