import yup, {
  booleanField,
  editedField,
  htmlField,
  idField,
  moneyField,
  notesField,
  nutritionField,
  optionalTextField,
  requiredTextField,
} from "src/lib/YupLib";
import { IDFIELD } from "src/modules/base/schema";
import { InferType } from "yup";

import { PRODUCT_TYPES } from "./ProductTypeLib";

export const PRODUCT_TABLE_NAME = "product";

export type ProductId = string;

export const PreparationStepSchema = yup
  .object({
    text: yup.string().required(),
    images: yup.array(yup.string().required()).defined().default([]),
  })
  .defined()
  .default([]);

export type PreparationStepData = InferType<typeof PreparationStepSchema>;

export const ProductSchema = yup
  .object({
    [IDFIELD]: idField,

    handle: requiredTextField,
    type: requiredTextField.oneOf(PRODUCT_TYPES),
    price: moneyField, // only for none mains
    unitsName: optionalTextField.default("each"), // size of the order - 125g punnet, each, etc..

    shopifyId: optionalTextField, // deprecated

    title: requiredTextField,
    subtitle: optionalTextField,

    // Google shopping feed
    googleTitle: optionalTextField,
    googleDescription: optionalTextField,
    googleCategory: optionalTextField,
    googleProductType: optionalTextField,
    googleImage: optionalTextField,

    // images
    image: optionalTextField,
    featuredImage: optionalTextField,

    // text
    description: htmlField,
    spiceRating: yup.number().min(0).max(3).default(0),

    // access
    isPublished: booleanField,
    isActive: booleanField,
    isNew: booleanField,
    isBestSeller: booleanField,
    isHidden: booleanField,
    isFeatured: booleanField,
    isComingSoon: booleanField,
    isLeavingSoon: booleanField,

    // admin
    packingZone: yup.number().required().default(0),

    // serving
    servingSize: nutritionField,
    servingsPerContainer: nutritionField,

    // nutrition
    calories: nutritionField,
    calorieSplit: nutritionField,
    energy: nutritionField,
    energySplit: nutritionField,
    protein: nutritionField,
    proteinSplit: nutritionField,
    fat: nutritionField,
    fatSplit: nutritionField,
    saturatedFat: nutritionField,
    saturatedFatSplit: nutritionField,
    carbohydrate: nutritionField,
    carbsSplit: nutritionField,
    sugar: nutritionField,
    sugarSplit: nutritionField,
    sodium: nutritionField,
    sodiumSplit: nutritionField,

    fibre: nutritionField, // not used on display

    allergens: yup
      .object({
        peanut: yup.boolean().nullable().default(false),
        gluten: yup.boolean().nullable().default(false),
        soy: yup.boolean().nullable().default(false),
      })
      .defined(),

    // allergens: yup.array(yup.string().required()).defined().default([]),
    ingredients: htmlField,

    // prep
    servingSuggestions: htmlField,
    backgroundStory: htmlField,
    preparationVideo: optionalTextField,
    preparationTime: yup.number(),
    preparationSteps: yup.array(PreparationStepSchema).default([]),
    preparationMicrowave: htmlField,

    // recommended
    recommended: yup.array(yup.string()).defined().default([]),

    // notes
    notes: notesField,

    // dates
    // created: createdField,
    edited: editedField,
  })
  .defined();

export type ProductData = InferType<typeof ProductSchema>;

export const ProductListSchema = yup
  .object({
    [IDFIELD]: idField,
    handle: requiredTextField,
    type: requiredTextField,
    price: moneyField,
    unitsName: optionalTextField,
    title: requiredTextField,
    subtitle: optionalTextField,
    image: optionalTextField,
    isActive: booleanField,
    isNew: booleanField,
    isBestSeller: booleanField,
    isHidden: booleanField,
    isFeatured: booleanField,
    featuredImage: optionalTextField,
    isComingSoon: booleanField,
    isLeavingSoon: booleanField,
  })
  .defined();

export type ProductListData = InferType<typeof ProductListSchema>;

export const ProductCacheSchema = yup
  .object({
    [IDFIELD]: idField,
    handle: requiredTextField,
    type: requiredTextField,
    price: moneyField,
    unitsName: optionalTextField,
    title: requiredTextField,
    image: optionalTextField,
    isActive: booleanField,
  })
  .defined();

export type ProductCacheData = InferType<typeof ProductCacheSchema>;
