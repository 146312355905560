import "src/styles/index.css";

import { Toaster } from "react-hot-toast";
import Modal from "react-modal";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ErrorBoundary from "src/layout/ErrorBoundary";
import MotionProgress from "src/layout/MotionProgress";
import StoreProvider from "src/services/store/StoreProvider";

Modal.setAppElement("body");

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }) => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <StoreProvider {...pageProps}>
          <MotionProgress />
          <Component {...pageProps} />
        </StoreProvider>
        <Toaster />
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default MyApp;
