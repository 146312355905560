import React, { FC } from "react";

const ToastIconSuccess: FC = () => {
  return (
    <svg className="w-full" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="white" />
      <rect x="20.8284" y="27.7782" width="9" height="4" transform="rotate(45 20.8284 27.7782)" fill="#9FDD9B" />
      <rect x="39.9203" y="20" width="4" height="23" transform="rotate(45 39.9203 20)" fill="#9FDD9B" />
    </svg>
  );
};

export default ToastIconSuccess;
