import React, { FC } from "react";

const ToastIconError: FC = () => {
  return (
    <svg className="w-full" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="white" />
      <path
        d="M28.67 18.61H32.87V23.95C32.87 24.76 32.84 25.54 32.84 26.29C32.81 27.04 32.78 27.76 32.72 28.45C32.63 29.89 32.54 31.09 32.42 32.05C32.3 33.01 32.21 33.64 32.15 33.91C31.97 33.88 31.79 33.85 31.55 33.82C31.31 33.82 31.07 33.79 30.77 33.79C30.47 33.79 30.2 33.82 29.96 33.82C29.72 33.85 29.51 33.88 29.39 33.91C29.33 33.79 29.3 33.55 29.24 33.25C29.18 32.95 29.15 32.53 29.09 32.05C28.97 31.09 28.88 29.89 28.79 28.45C28.7 27.04 28.67 25.54 28.67 23.95V18.61ZM30.71 40.39C29.75 40.39 29.06 40.21 28.67 39.82C28.25 39.43 28.07 38.74 28.07 37.75C28.07 36.79 28.28 36.1 28.73 35.71C29.18 35.35 29.84 35.14 30.71 35.14C31.58 35.14 32.24 35.35 32.69 35.71C33.11 36.1 33.35 36.79 33.35 37.75C33.35 38.71 33.11 39.37 32.69 39.79C32.24 40.21 31.58 40.39 30.71 40.39Z"
        fill="#EE5B5B"
      />
    </svg>
  );
};

export default ToastIconError;
