import { isObject } from "lodash";
import { ObjectSchema } from "yup";

import {
  firestoreClientDelete$,
  firestoreClientGet$,
  firestoreClientSet$,
  firestoreClientUpdate$,
} from "../../services/firebase/client";
import { getId } from "./lib";
import { BaseData } from "./schema";

export const baseClientGet$ = async (
  collectionName: string,
  id: string | BaseData,
  schema: ObjectSchema<any> | null = null,
): Promise<object | null> => {
  let doc = isObject(id) ? id : await firestoreClientGet$(collectionName, id);
  if (doc && schema) doc = schema.cast(doc)!;
  return doc;
};

export const baseClientSet$ = async (collectionName: string, values: any, schema: ObjectSchema<any> | null = null) => {
  values = {
    ...values,
    edited: Date.now(),
  };
  if (schema) values = schema.cast(values, { stripUnknown: true });
  // console.log("baseClientSet", collectionName, values);
  return firestoreClientSet$(collectionName, values);
};

export const baseClientUpdate$ = async (collectionName: string, values: any) => {
  values.edited = Date.now();
  firestoreClientUpdate$(collectionName, values);
};

export const baseClientDelete$ = async (collectionName: string, id: string | BaseData) => {
  id = getId(id) as string;
  if (!id) throw new Error("no id provided");
  return firestoreClientDelete$(collectionName, id);
};
