/**
 * Base of a Data Object
 */

import { InferType } from "yup";

import yup, { idField } from "../../lib/YupLib";

/**
 * ID Field
 *
 * - used to copy record ID into object
 */

export const IDFIELD = "_id";

/** Schema */

export const BaseSchema = yup
  .object({
    [IDFIELD]: idField,
  })
  .defined();

export type BaseData = InferType<typeof BaseSchema>;
