import React, { FC } from "react";
import { LottieAnimation } from "react-lottie-tools";

const LottieProgressSpinner: FC = () => {
  return (
    <LottieAnimation
      autoplay={false}
      loop={false}
      justPlayInView
      inViewSettings={{ threshold: 1 }}
      speed={0.5}
      style={{ height: "auto", width: "120px" }}
      animation="/lottie/transition/VOOD-rubiks-loader.json"
    />
  );
};

export default LottieProgressSpinner;
