import clsx from "clsx";
import { createElement } from "react";
import toast from "react-hot-toast";
import CrossToastIcon from "src/images/CrossToastIcon";
import ToastIconError from "src/images/ToastIconError";
import ToastIconMessage from "src/images/ToastIconMessage";
import ToastIconSuccess from "src/images/ToastIconSuccess";

export const toastAlert = (message, icon, className: string = "") => {
  const handleClose = () => toast.remove();

  // Clear existing toasts
  toast.remove();

  toast.custom(
    <div
      className={clsx(
        "px-2 py-2 mt-16",
        "min-w-toast",
        "rounded-toast",
        "font-sharp-grotesk-medium text-ts",
        "flex items-center gap-6",
        className,
      )}
    >
      <div className="flex-shrink-0 w-12">{createElement(icon)}</div>
      <div className="flex-grow text-xs uppercase pr-3">{message}</div>
      <button onClick={handleClose} className="flex-shrink-0 w-4 h-4 mr-4 hover:opacity-75">
        <CrossToastIcon />
      </button>
    </div>,
    {
      position: "top-right",
    },
  );
};

export const toastInfo = (message: string) => toastAlert(message, ToastIconMessage, "bg-[#83D7E9] text-charcoal");

export const toastSuccess = (message: string = "Success") =>
  toastAlert(message, ToastIconSuccess, "bg-[#9FDD9B] text-charcoal");

export const toastWarning = (message: string) => toastAlert(message, ToastIconError, "bg-yellow-200 text-charcoal");

export const toastError = (message: string = "Error") => toastAlert(message, ToastIconError, "bg-[#EE5B5B] text-white");

export const toastSaved = (message = "Changes have been saved") =>
  toastAlert(message, ToastIconSuccess, "bg-[#9FDD9B] text-charcoal");

export const toastDeleted = (message = "Record has been deleted") =>
  toastAlert(message, ToastIconSuccess, "bg-yellow-200 text-charcoal");
