import { where } from "firebase/firestore";
import { useQuery } from "react-query";
import { apiGet$ } from "src/lib/APILib";
import { store } from "src/services/store/store";

import { firestoreClientQuery$ } from "../../services/firebase/client";
import { baseClientDelete$, baseClientGet$, baseClientSet$ } from "../base/client";
import { productHasVariants } from "./lib";
import { PRODUCT_TABLE_NAME, ProductCacheData, ProductData, ProductListData, ProductSchema } from "./schema";
import { VARIANT_EACH, VARIANT_SINGLE, VariantType } from "./VariantLib";

export const productClientGet$ = (id) => baseClientGet$(PRODUCT_TABLE_NAME, id, ProductSchema);

export const productClientSet$ = (values) => baseClientSet$(PRODUCT_TABLE_NAME, values, ProductSchema);

export const productClientDelete$ = async (id: string) => baseClientDelete$(PRODUCT_TABLE_NAME, id);

export const productClientLoadStore$ = async () => {
  const products = await apiGet$(["cache", "products"]);
  store.products = products;
};

export const productClientGetByHandle$ = async (handle: string) => {
  // console.log("productClientGetByHandle$", handle);
  const data = await firestoreClientQuery$(PRODUCT_TABLE_NAME, [where("handle", "==", handle)]);
  return data?.[0] || null;
};

export const productClientUpdateByHandle$ = async (product: ProductData) => {
  // console.log("productClientUpdateByHandle$", old, product);
  const { handle } = product;
  if (!handle) throw new Error("No handle");
  const old = await productClientGetByHandle$(handle);
  return productClientSet$({ ...old, ...product });
};

export const productClientDefaultVariant = (product: ProductData | ProductListData | ProductCacheData): VariantType => {
  // if no variants, use default.
  // if plan, use that. otherwise single
  // client function as relies on store
  if (!store) return VARIANT_EACH; // when loading
  if (!productHasVariants(product)) return VARIANT_EACH;
  if (!store.plan) return VARIANT_SINGLE;
  return store.plan.variant;
};

export const useProductById = (id: string) => {
  return useQuery<ProductData | null>(["product", id], async () => {
    const product = await apiGet$(["product", id]);
    return product;
  });
};

export const useProductCache = () => {
  return useQuery<ProductCacheData[]>(["product", "cache"], async () => {
    const product = await apiGet$(["cache", "products"]);
    return product;
  });
};
