import { onAuthStateChanged, User } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { assign } from "lodash";
import { action, computed, configure, makeObservable, observable, reaction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { enableStaticRendering } from "mobx-react-lite";
import { IS_SERVER } from "src/lib/SSR";
import { CartItem } from "src/modules/cart/schema";
import { StoreDiscount, StorePlan } from "src/modules/plan/schema";
import { productClientLoadStore$ } from "src/modules/product/client";
import { ProductCacheData } from "src/modules/product/schema";
import { USER_TABLE_NAME, UserData } from "src/modules/user/schema";
import { auth, firestoreClient } from "src/services/firebase/client";
import { firestoreDocToData } from "src/services/firebase/lib";

import { deliveryShippingZonesClientLoadStore$ } from "../../modules/delivery/client";
import { ShippingZonesData } from "../../modules/delivery/schema";

enableStaticRendering(IS_SERVER);

configure({
  enforceActions: "never",
});

export interface ProductCache {
  [productId: string]: ProductCacheData;
}

export type ShippingZonesCache = ShippingZonesData[];

export class Store {
  authUnsubscribe;
  profileUnsubscribe;

  authRecord: User = null;
  profile: UserData | null = null;

  cart: CartItem[] = []; // items in the cart
  plan: null | StorePlan = null; // current plan details
  discount: null | StoreDiscount = null;
  postcode: string = null;
  pack: any = null; // slug page details
  suburb: any = null;

  orderId: null | string = null; // edit this 'future' order
  orderDate: null | string = null; // date of 'future' order

  modals: any[] = []; // modal queue
  products: ProductCache = null; // reference
  shippingZones: ShippingZonesCache = null; // reference
  showCart: boolean = false; // mobile only
  showLogin: boolean = false;
  showMainMenu: boolean = false;
  modalOpen: boolean = false;
  isModifyingPlan: boolean = false;

  constructor() {
    this.emptyCart = this.emptyCart.bind(this);
    this.setShowLogin = this.setShowLogin.bind(this);
    this.setShowCart = this.setShowCart.bind(this);
    this.setShowMainMenu = this.setShowMainMenu.bind(this);
    this.setPostcode = this.setPostcode.bind(this);
    this.setSuburb = this.setSuburb.bind(this);
    this.setModalOpen = this.setModalOpen.bind(this);
    this.setIsModifyingPlan = this.setIsModifyingPlan.bind(this);

    makeObservable(this, {
      authRecord: observable,
      profile: observable,
      cart: observable,
      plan: observable,
      discount: observable,
      orderId: observable,
      modals: observable,
      products: observable,
      shippingZones: observable,
      showCart: observable,
      showLogin: observable,
      showMainMenu: observable,
      modalOpen: observable,
      isModifyingPlan: observable,
      hydrate: action,
      emptyCart: action,
      setShowLogin: action,
      setShowCart: action,
      setShowMainMenu: action,
      setPostcode: action,
      setModalOpen: action,
      isLoggedIn: computed,
      isAdmin: computed,
      currentUserId: computed,
      currentProfile: computed,
      isModifying: computed,
      isModalOpen: computed,
    });

    reaction(
      () => this.authRecord?.uid,
      () => {
        // console.log("authRecord changes", this.authRecord);
        this.profileUnsubscribe?.();
        if (!this.isLoggedIn) {
          this.profile = null;
        } else {
          this.subscribeToUser();
        }
      },
    );

    reaction(
      () => this.profile?.address?.postcode,
      () => {
        const tmp = this.profile?.address?.postcode || null;
        console.log("postcode changes", tmp, this.postcode);
        if (tmp) this.postcode = tmp;
      },
    );

    reaction(
      () => this.profile?.address?.suburb,
      () => {
        const tmp = this.profile?.address?.suburb || null;
        console.log("suburb changes", tmp, this.suburb);
        if (tmp) this.suburb = tmp;
      },
    );

    // Persist store

    if (!IS_SERVER) {
      makePersistable(
        this,
        {
          name: "vood",
          properties: ["cart", "plan", "postcode", "orderId", "orderDate", "isModifyingPlan"],
          storage: window.localStorage,
        },
        { delay: 200, fireImmediately: false },
      );
      this.subscribeToAuth();
      // Load reference products
      productClientLoadStore$();
      deliveryShippingZonesClientLoadStore$();
    }
  }

  get isLoggedIn() {
    return !!this.authRecord;
  }

  get isAdmin() {
    return this.authRecord && this.profile?.admin;
  }

  get currentUserId() {
    return this.authRecord?.uid || null;
  }

  get currentProfile() {
    return this.profile || null;
  }

  get isModifying() {
    return !!this.orderId;
  }

  get isModalOpen() {
    return this.modalOpen;
  }

  emptyCart() {
    this.cart = [];
    this.plan = null;
    this.orderId = null;
    this.orderDate = null;
  }

  setShowLogin(showLogin: boolean = true) {
    this.showLogin = showLogin;
  }

  setShowCart(showCart: boolean = true) {
    this.showCart = showCart;
  }

  setIsModifyingPlan(isModifyingPlan: boolean = true) {
    this.isModifyingPlan = isModifyingPlan;
  }

  setShowMainMenu(showMainMenu: boolean = true) {
    this.showMainMenu = showMainMenu;
  }

  setPostcode(postcode: string) {
    this.postcode = postcode;
  }

  setSuburb(suburb: string) {
    this.suburb = suburb;
  }

  setModalOpen(open: boolean) {
    this.modalOpen = open;
  }

  hydrate = (data) => {
    if (!data) return;
    assign(this, data);
  };

  subscribeToAuth = () => {
    this.authUnsubscribe = onAuthStateChanged(
      auth,
      action((authRecord: User) => {
        this.authRecord = authRecord;
        this.showLogin = false;
      }),
    );
  };

  subscribeToUser = () => {
    const docRef = doc(firestoreClient, USER_TABLE_NAME, this.authRecord.uid);
    this.profileUnsubscribe = onSnapshot(docRef, (snapshot) => {
      this.profile = firestoreDocToData(snapshot);
    });
  };
}

export let store: Store;

export const initializeStore = (initialData = null) => {
  const _store = store ?? new Store();

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
  if (initialData) {
    _store.hydrate(initialData);
  }
  // For SSG and SSR always create a new store
  if (IS_SERVER) return _store;

  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};
