import yup from "src/lib/YupLib";
import { InferType } from "yup";

export const AddressSchema = yup.object({
  street: yup.string().defined().ensure().required("Street is a required field"),
  city: yup.string().defined().ensure().required("City is a required field"),
  postcode: yup
    .string()
    .defined()
    .ensure()
    .min(4, "Postcode must be 4 characters")
    .max(4, "Postcode must be 4 characters")
    .required("Postcode is a required field"),
  state: yup.string().defined().ensure().required("State is a required field"),
  notes: yup.string().defined().ensure(),
});

export type AddressData = InferType<typeof AddressSchema>;
